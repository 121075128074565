.downloadButtonContainer {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 75%;
  transform: translateY(-50%);
}
.downloadButton {
  --border-color: #1d2b4d;
  color: #1d2b4d;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 3vh;
  height: 8vh;
}
.downloadButton2 {
  --background: #1d2b4d;
  color: #ffffff !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 3vh;
  height: 8vh;
}
.downloadButtonCell{
  padding: 0 !important;
}
.downloadVersion {
  color: black;
}